import { render, staticRenderFns } from "./gx.vue?vue&type=template&id=0efee213&scoped=true"
import script from "./gx.vue?vue&type=script&lang=js"
export * from "./gx.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0efee213",
  null
  
)

export default component.exports