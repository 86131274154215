<!--  -->
<template>
  <div class=''></div>
</template>

<script>
import Api from '@/api/home'
import MApi from '@/api/my.js' 

export default {
  components: {},
  data() {
    return {
      
    };
  },
  computed: {},
  watch: {},
  created() {
    if (window.location.href && window.location.href.indexOf('applyInfo') != -1) {
      let search = window.location.href.split('?')[1]
      if (search.indexOf('&') != -1) {
        search = search.split('&')
        for (let i in search) {
          if (search[i].indexOf('applyInfo') != -1) {
            search = search[i]
          }
        }
      }
      if(search.indexOf('applyInfo') != -1) {
        let options = {
          applyInfo: search.split("=")[1],
          user_type: '2'
        }
        this.otherLogin(options)
      }
    }
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    otherLogin (form) {
      Api.otherLogin({
        applyInfo: form.applyInfo,
        user_type: form.user_type
      }).then(res => {
        if (res.data.code == 1) {
          localStorage.setItem('year', res.data.year)
          localStorage.setItem('token', res.data.token)
          this.$store.commit('setToken', res.data.token)
          this.getUserInfo(res.data.year)
        } else {
          this.$message.error('登录失败')
        }
      })
    },
    getUserInfo (year) {
      MApi.getUserInfo({year: year}).then(res => {
        if (res.status == 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.$router.push({name: 'Gongxu'})
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>

</style>